import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

/* eslint-disable */

const ThreeColumn = ({ gridItems, ctaAction }) => {
  return (
    <div className="columns is-multiline">
        {gridItems.map((item, i) => (
          <div key={i} className={`column is-12 three-column row-${i} `}>
            <section className="section columns">
              <div className="has-text-centered column is-12">
                {item.cta && <div className="cta"><a className="button" onClick={ctaAction} >{ item.text }</a></div> }
                {!item.cta &&
                  <div>
                    <div className="three-column__image-wrapper image__wrapper"
                      style={{
                        maxWidth: '450px',
                        width: '100%',
                        display: 'inline-block',
                      }}
                    >
                      <PreviewCompatibleImage imageInfo={item} />
                    </div>
                    <div className={`column-content body-${i}`}>
                      {item.header ? <h3>{item.header}</h3> : ''}
                      <div className="three-column--text-body"
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </div>
                  </div>
                }
              </div>
            </section>
          </div>
        ))}
    </div>
  )
}


ThreeColumn.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      cta: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
}

export default ThreeColumn
