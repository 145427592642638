import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import HubspotForm from 'react-hubspot-form'
import Layout from '../components/Layout'
import Pricing from '../components/Pricing'
import ThreeColumn from '../components/ThreeColumn'
import SubNavbar from '../components/SubNavbar'

export const PricingPageTemplate = ({
  image,
  title,
  metadescription,
  mainpitch,
  pricing,
  services,
  reasons,
  form,
  helmet,
  ctaDisplay,
  setCtaDisplay
}) => (
  <div className="pricing-pg dark-bg-offset">
    {helmet || ''}
    <div
      className="full-width-image margin-top-0 hero-bg"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center center`,
        justifyContent: 'left',
        flexDirection: `column`,
        height: 'auto',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container" >
        <div className="columns">
          <div className="column is-12">
            <div className="hero-text"
              style={{
                display: 'flex',
                lineHeight: '1',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h1
                className="has-text-weight-bold"
                style={{
                  color: '000000',
                  fontSize: '44px',
                  lineHeight: '52px',
                }}
              >{mainpitch.title}</h1>
              <p>{mainpitch.pretext}</p>
              <div className="cta-btn cta-btn__secondary">
                <a href={mainpitch.linktarget}>{mainpitch.linktext}</a>
              </div>
              <p>
                {mainpitch.subtext}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section" style={{ padding: '0' }}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">
                <div className="columns">
                  <div className="column is-12 test">
                    <Pricing data={pricing.plans} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{padding: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">
                <div className="columns services-section">
                  <div className="column is-12">
                    <h2 style={{
                      maxWidth: '500px',
                      margin: '20px auto 14px',
                      textAlign: 'center'
                    }}>{services.title}</h2>
                    <p style={{
                      maxWidth: '576px',
                      margin: '0 auto 48px',
                      textAlign: 'center'
                    }}>{services.description}</p>
                  </div>
                </div>
                <div className="columns reasons-section">
                  <div className="column is-12">
                    <h4 style={{
                      maxWidth: '350px',
                      margin: '0 auto',
                      textAlign: 'center'
                    }}>{services.subtitle}</h4>
                    <ThreeColumn gridItems={reasons} ctaAction={setCtaDisplay} />
                  </div>
                </div>
                <div className="columns form-section" id="cta-form" style={ ctaDisplay ? { display: 'flex' } : { display: 'none'} }>
                  <div className="column is-12 hubspot-form">
                    <HubspotForm
                      portalId={form.portalid}
                      formId={form.formid}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

PricingPageTemplate.propTypes = {
  software: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  metadescription: PropTypes.string,
  mainpitch: PropTypes.object,
  pricing: PropTypes.shape({
    plans: PropTypes.array,
  }),
  services: PropTypes.object,
  reasons: PropTypes.array,
  form: PropTypes.object,
  helmet: PropTypes.object,
}

const PricingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const [ctaDisplay, setCtaDisplay] = useState(false);

  return (
    <div className="dark-bg-hero pricing-pg-wrapper subnavtop">
      <SubNavbar
        software={frontmatter.software}
      />
      <Layout>
        <PricingPageTemplate
          {...frontmatter}
          helmet={
            <Helmet titleTemplate="%s | Human">
              <title>{`${frontmatter.title}`}</title>
              <meta name="description" content={`${frontmatter.metadescription}`} />
            </Helmet>
          }
          ctaDisplay={ctaDisplay}
          setCtaDisplay={setCtaDisplay}
        />
      </Layout>
    </div>
  )
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PricingPage

export const pageQuery = graphql`
  query PricingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "pricing-page" } }) {
      frontmatter {
        software
        title
        metadescription
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mainpitch {
          title
          pretext
          linktarget
          linktext
          subtext
        }
        pricing {
          plans {
            plan
            beforeprice
            price
            annualprice
            afterprice
            description
            buttontitle
            buttonurl
            included
            items {
              itemheader
              item
            }
          }
        }
        services {
          title
          description
          subtitle
        }
        reasons {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 100, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text
          cta
        }
        form {
          portalid
          formid
        }
      }
    }
  }
`
