import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ToggleSwitch from '../components/ToggleSwitch'




const Pricing = ({ data }) => {
  const [pricingType, setPricingType] = useState(false);


  return (
  <div id="pricingSection">
    <div className="columns pricing__header" style={{
      justifyContent: 'center',
      paddingBottom: '4rem'
    }}>
      <span className="toggle-text">Monthly</span>
      <ToggleSwitch id="pricingTerm" Text={["", ""]} onSwitchChange={setPricingType} />
      <span className="toggle-text">Yearly <span className="toggle-text-discount">(20% off)</span></span>
    </div>
    <div className="columns pricing__section">
    {data.map(price => (
      <div key={price.plan} className="column">
        <section className="section pricing__plan">
          <div className="pricing__plan--star" style={{
                position: 'absolute',
                top: '-15px',
                left: '12px',
                fontSize: '12px',
                color: '#FFDC1D',
          }}>
            <FontAwesomeIcon icon="star" />
          </div>
          <p className="pricing__plan--descrip">{price.description}</p>
          <h4 className="has-text-weight-semibold is-size-4 pricing__plan--title">
            {price.plan}
          </h4>
          <div className="pricing__plan--price-wrap">
            <p className="pricing__plan--price-before">{price.beforeprice}</p>
            <h2 className="is-size-4 pricing__plan--price">
              ${pricingType ? price.annualprice : price.price}
            </h2>
            <p className="pricing__plan--price-after">{price.afterprice}</p>
          </div>          
          <ul className="pricing__plan--list">            
            {price.items.map(item => (
                <li key={item.itemheader}>                  
                  <ul>
                    {item.item.map(item => ( 
                        <li key={item} className="is-size-6">
                        <span className="pricing__plan--list-icon"><FontAwesomeIcon icon="check-circle" /></span> {item}
                      </li>
                    ))}
                  </ul>                  
                </li>
            ))}            
          </ul>
          <div className="pricing__plan--bottom">
            <div className="pricing__plan--btn btn-wrapper">            
              <a className="btn-cta" href={`${price.buttonurl}`}>{price.buttontitle}</a>
            </div>             
          </div>
        </section>
      </div>
    ))}
      <div className="column">
        <section className="section pricing__plan bigger-plans">
          <h4 className="has-text-weight-semibold is-size-4 pricing__plan--title has-text-centered">
              Got bigger plans? 
          </h4>
          <div className="pricing__plan--btn-buynow">
              <a href="https://inbound.human.marketing/meetings/joel100/personalize-demo" className="btn-underline" target="_blank" rel="noopener noreferrer">Request a demo</a>
          </div>
          <div className="tel-wrapper has-text-centered"><a className="tel" href="tel:949-416-2043">949.416.2043</a></div>
        </section>
      </div>
  </div>
  </div>
  )
}

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      annualprice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      buttontitle: PropTypes.string,
      buttonurl: PropTypes.string,
      description: PropTypes.string,
      included: PropTypes.string,
      items: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    })
  ),
}

export default Pricing
