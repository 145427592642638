import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import personalizeLogo from "../img/logo-personalize.png";
import blueprintlogo from "../img/logo-blueprint-white-300w.png";
import { useSpring, animated } from "react-spring";

/* eslint-disable */

const SubNavbar = ({ software }) => {
  const parentURL = '/' + software + '/';
  const parentNavClass = software + '-nav';
  const [isActive, setActive] = useState(false);
  const [on, toggle] = useState(false);
  const navAnimation = useSpring({
    opacity: isActive ? `1` : `0`
  });

  const brandLogo = (software === 'personalize' ? personalizeLogo : blueprintlogo);
  const useCaseURL = (software === 'personalize' ? parentURL + 'work/new-horizons-case-study/' : '');
  const pricingURL = (software === 'personalize' ? parentURL + 'pricing/' : '');
  const supportURL = (software === 'personalize' ? 'http://help.humanautomation.ai/en/collections/1778760-personalize' : '');
  const ctaURL = (software === 'personalize' ? 'https://personalize.humanautomation.ai/signup' : 'https://blueprintseo.humanautomation.ai/signup');
  const ctaText = (software === 'personalize' ? '<span>START</span> FREE TRIAL' : 'TRY FOR FREE');

  let [pos, setPos] = useState(false)
  let [visible, setVisible] = useState(true)

  useEffect(()=> {
    const handleScroll = () => {
       let temp = window.pageYOffset

       setVisible(pos > temp);
       setPos(temp)
    };
    window.addEventListener("scroll", handleScroll);
    return(() => {
       window.removeEventListener("scroll", handleScroll);
    })
 })

  return (
    <div className={(!visible ? "navbarHidden" : " ")}>
    <animated.nav
      className={`navbar subnavbar is-fixed-top ${on ? "is-active" : ""}`}
      role="navigation"
      aria-label="main-navigation"
      id="main-nav"
    >
      <div className={`container ${parentNavClass} `}>
        <div className={`navbar-brand ${isActive ? "is-active" : ""}`} >
          <div className="navbar-item logo" title="Logo">
            <Link to={parentURL}>
                <img src={brandLogo} alt="human automation personalize" style={{ width: "150px", maxHeight: "50px" }} />
            </Link>
          </div>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            data-target="navMenu"
            onClick={() => setActive(!isActive)}
          >
            <div className="subnav-mobile-logo">
              <img src={brandLogo} alt="human automation personalize" style={{ width: "155px", maxHeight: "50px" }} />
            </div>
          </div>
        </div>
        <animated.div
          id="navMenu"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          style={navAnimation}
        >
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item navbar-mobile-only navbar-item-overview" to={parentURL} >
              <span>Overview</span>
            </Link>
            {software === 'blueprint-seo' && (
              <Link className="navbar-item navbar-item-features" to="/blueprint-seo/features">
                <span>Features</span>
              </Link>
            )}
            {software === 'personalize' && <>
              <Link className="navbar-item navbar-item-casestudy" to={useCaseURL}>
                <span>Use Cases</span>
              </Link>
              <Link className="navbar-item navbar-item-pricing" to={pricingURL}>
                <span>Pricing</span>
              </Link>
              <Link className="navbar-item navbar-item-support" to={supportURL}>
                <span>Support</span>
              </Link>
            </>}

          </div>
          <div className="navbar-end" >

          </div>
        </animated.div>
        <div className="navbar-right">
            <a className="has-text-weight-bold btn nav-cta" href={`${ctaURL}`} rel="noopener noreferrer">
              <span dangerouslySetInnerHTML={{ __html: ctaText}}></span>
            </a>
        </div>
      </div>
    </animated.nav>

    </div>
  );
};

export default SubNavbar;
